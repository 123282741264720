<template>
  <section class="py-5">
    <div class="mb-5">
      <CContainer>
        <CRow class="align-items-end">
          <CCol md="6">
            <CRow>
              <CCol xl="9" xxl="9">
                <section-title
                    tagline="Our Team"
                    :title="title"
                    :subtitle="description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6" class="text-end">
            <router-link to="/team" class="btn btn-primary"
            >View All Members
            </router-link
            >
          </CCol>
        </CRow>
      </CContainer>
    </div>
    <CContainer>

      <CRow>
        <CCol
            sm="6"
            md="3"
            v-for="teamMember in teamMembers"
            :key="teamMember.id"
        >
          <team-member
              :member="teamMember"
          />
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import TeamMember from "@/components/shared-components/team-member/TeamMember.vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {ref} from "vue";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
  name: "TeamBlock",
  components: {TeamMember, SectionTitle},
  props: ['title', 'description'],

  data() {
    return {
      teamMembers: ref([]),
    };
  },

  mounted() {
    this.getFeaturedTeamMember();
  },

  methods: {
    getFeaturedTeamMember() {
      const url = UrlConfig.urlHelper.hostUrl + '/get-feature-team';
      const header = UrlConfig.unAuthorizedUrlHeader;
      axios.get(url, header)
          .then(response => {
            let teamMember = response.data.data;
            if (response.data.status_code === 200 && teamMember.length > 0) {
              Object.entries(teamMember).forEach(([key, value]) => {
                this.teamMembers.push({
                  id: ++key,
                  name: value.name,
                  slug: value.slug,
                  designation: value.designation_name,
                  image: value.image_path,
                });
              });
            }
          }).catch((error) => {
              toast.error('something went wrong', {
                timeout: 2000,
              });
          });
    }


  },

};
</script>

<style lang="scss" scoped></style>
