<template>
  <section>
    <page-banner
        :image=image
    >
      <div class="col-md-7">
        <banner-title
          :tagline=tagline
          :title = title
          :description=description
        />
      </div>
    </page-banner>
  </section>
</template>

<script>
import PageBanner from "@/components/shared-components/page-banner/PageBanner.vue";
import BannerTitle from "@/components/shared-components/banner-title/BannerTitle.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {useToast} from "vue-toastification";
const toast = useToast();

export default {
    components: { PageBanner, BannerTitle },
    name: "AboutBanner",

    data() {
      return {
        tagline : ref(''),
        image : ref(''),
        title : ref(''),
        description : ref(''),

      }
    },

    created() {
      this.getBannerDetail();
    },

    methods: {
      getBannerDetail(){
        const url = UrlConfig.urlHelper.hostUrl +'/get-page-banner/about_us' ;
        const header = UrlConfig.unAuthorizedUrlHeader;
        axios.get(url,header)
            .then(response => {
              let aboutBannerData = response.data.data ;
              if(response.data.status_code === 200 && aboutBannerData.page_image_path !== ''){
                this.image =  aboutBannerData.page_image_path ? aboutBannerData.page_image_path : '/img/banners/pageban01.png';
                this.title = aboutBannerData.page_title;
                this.description = aboutBannerData.page_title_description;
                this.tagline = aboutBannerData.page_sub_title;
              }
            }).catch((error) => {
                toast.error(error.response.data.message, {
                  timeout: 2000,
                });
        });
      },
    },

  };
</script>

<style lang="scss" scoped></style>
