<template>
  <section class="py-5">
    <div class="mb-5">
      <CContainer>
        <CRow class="align-items-end">
          <CCol md="6" class="md-md-0 mb-4">
            <CRow>
              <CCol xl="9" xxl="9">
                <section-title
                  tagline="Our Showrooms"
                  :title="title"
                  :subtitle="description"
                />
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6" class="text-end">
            <router-link to="/showrooms" class="btn btn-primary"
              >View All Showrooms</router-link
            >
            <div class="ms-3 d-inline-flex">
              <swiper-nav
                leftClassName="shworoomPprevArrow"
                RightClassName="shworoomNextArrow"
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    <CContainer>
      <CRow>
        <CCol md="12">
          <swiper
            :modules="modules"
            :breakpoints="breakpoints"
            :autoHeight="false"
            :space-between="50"
            :navigation="{
              nextEl: '.shworoomNextArrow',
              prevEl: '.shworoomPprevArrow',
            }"
            :pagination="{ clickable: true }"
            :scrollbar="{ draggable: true }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide v-for="showroom in showrooms" :key="showroom.id">
              <showroom-card :showroom="showroom" />
            </swiper-slide>
          </swiper>
        </CCol>
      </CRow>
    </CContainer>
    <CToaster placement="bottom-end">
      <CToast :delay="time" v-for="(toast, index) in toasts" :key="index">
        <CAlert class="m-0" :color="toast.color"> {{ toast.content }} </CAlert>
      </CToast>
    </CToaster>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SectionTitle from "@/components/shared-components/section-title/SectionTitle.vue";
import SwiperNav from "@/components/shared-components/swiper-nav/SwiperNav.vue";
import ShowroomCard from "@/components/shared-components/showroom-card/ShowroomCard.vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import {ref} from "vue";
export default {
  components: { SectionTitle, SwiperNav, ShowroomCard, Swiper, SwiperSlide },
  name: "ShowroomBlock",
  props:['title','description'],


  data() {
    return {
      time: 5000,
      toasts: [],
      toastMessage: "Toast Message",
      toastColor: "success",
      showrooms: ref([]),
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },

        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    };
  },

  mounted() {
    this.getFeatureShowRooms();
  },

  methods: {
   async getFeatureShowRooms(){
      const url = UrlConfig.urlHelper.hostUrl +'/get-featured-showroom' ;
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios.get(url,header)
          .then(response => {
            let showRoomDetail = response.data.data;
            if(response.data.status_code === 200 && showRoomDetail.length > 0){
              Object.entries(showRoomDetail).forEach(([key, value]) => {
                this.showrooms.push({
                  id: ++key,
                  address: value.address,
                  showroomName: value.name,
                  phone: value.contact_no,
                  contactPerson: value.contact_person,
                  image: value.image_path,
                });
              });
            }
          }).catch((error) => {
              this.toasts.push({
                color: "danger",
                content: error.response.data.message,
              });
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>
