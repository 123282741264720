<template>
  <about-banner />
  <about-body
    :title="aboutUsDetail.about_us_title"
    :subtitle="aboutUsDetail.about_us_sub_title"
    :description="aboutUsDetail.about_us_description"
  />

  <about-video-gallery />

  <core-values />

  <home-video
    :bannerTitle="aboutUsDetail.who_we_are_title"
    :bannerSubTitle="aboutUsDetail.who_we_are_sub_title"
    :bannerDescription="aboutUsDetail.who_we_are_description"
    :bannerImage="aboutUsDetail.who_we_are_image"
    :bannerVideo="bannerVideo"
  />

  <callouts />
  <who-we-are
    :sloganTitle="aboutUsDetail.slogan_title"
    :sloganSubTitle="aboutUsDetail.slogan_sub_title"
    :sloganDescription="aboutUsDetail.slogan_description"
    :sloganTitle1="aboutUsDetail.slogan_title_1"
    :sloganDescription1="aboutUsDetail.slogan_description_1"
    :sloganTitle2="aboutUsDetail.slogan_title_2"
    :sloganDescription2="aboutUsDetail.slogan_description_2"
  />

  <team-block
    :title="aboutUsDetail.team_title"
    :description="aboutUsDetail.team_description"
  />

  <career-link-banner />

  <section class="py-5">
    <testimonials
      :title="aboutUsDetail.testimonial_title"
      :subtitle="aboutUsDetail.testimonial_sub_title"
      :description="aboutUsDetail.testimonial_description"
    />
  </section>

  <rider-stories
    :title="aboutUsDetail.rider_story_title"
    :subtitle="aboutUsDetail.rider_story_sub_title"
    :description="aboutUsDetail.rider_story_description"
    :image="aboutUsDetail.rider_story_image"
  />

  <showroom-block
    :title="aboutUsDetail.showroom_title"
    :description="aboutUsDetail.showroom_description"
  />

  <brands
    :title="aboutUsDetail.brand_title"
    :subTitle="aboutUsDetail.brand_description"
  />

  <newsletter-sub />
</template>

<script>
import AboutBanner from "@/containers/business/about-banner/AboutBanner.vue";
import AboutBody from "@/containers/business/about-body/AboutBody.vue";
import CoreValues from "@/containers/business/core-values/CoreValues.vue";
import HomeVideo from "@/containers/business/home-video/HomeVideo.vue";
import Callouts from "@/containers/business/callouts/Callouts.vue";
import WhoWeAre from "@/containers/business/who-we-are/WhoWeAre.vue";
import TeamBlock from "@/containers/business/team-block/TeamBlock.vue";
import CareerLinkBanner from "@/containers/business/career-link-banner/CareerLinkbanner.vue";
import Testimonials from "@/containers/business/testimonials/Testimonials.vue";
import RiderStories from "@/containers/business/riders-stories/RiderStories.vue";
import Brands from "@/containers/business/brands/Brands.vue";
import ShowroomBlock from "@/containers/business/showroom-block/ShowroomBlock.vue";
import NewsletterSub from "@/containers/business/newsletter-sub/NewsletterSub.vue";
import AboutVideoGallery from "@/containers/business/about-video-gallery/AboutVideoGallery.vue";
import { ref } from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
import {helper} from "@/helper/Helper";
const toast = useToast();
export default {
  components: {
    AboutBanner,
    AboutBody,
    CoreValues,
    HomeVideo,
    Callouts,
    WhoWeAre,
    TeamBlock,
    CareerLinkBanner,
    Testimonials,
    RiderStories,
    Brands,
    ShowroomBlock,
    NewsletterSub,
    AboutVideoGallery,
  },
  name: "About",

  data() {
    return {
      aboutUsDetail: ref(""),
      bannerVideo: ref(""),
    };
  },

  mounted() {
    window.scrollTo(0, 0);
    let helperObj = new helper();
    helperObj.resetLocalStorageProductDataFilter();
    this.getAboutUsDetail();
    this.$store.dispatch("about/fetchAboutContent");
  },

  methods: {
    async getAboutUsDetail() {
      const url = UrlConfig.urlHelper.hostUrl + "/get-about-us-detail";
      const header = UrlConfig.unAuthorizedUrlHeader;
      await axios
        .get(url, header)
        .then((response) => {
          let aboutUsDetail = response.data.data;
          if (response.data.status_code === 200 && aboutUsDetail !== "") {
            this.aboutUsDetail = aboutUsDetail;
            this.bannerVideo =
              "https://www.youtube.com/embed/" +
              aboutUsDetail.who_we_are_youtube;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            timeout: 2000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
