<template>
  <section class="py-5">
    <CContainer>
      <CRow>
        <CCol sm="6" md="3" v-for="(callout,index) in callouts" :key="index">
          <callout :callout="callout" />
        </CCol>
      </CRow>
    </CContainer>
  </section>
</template>

<script>
import Callout from "@/components/shared-components/callout/Callout.vue";
import {ref} from "vue";
import UrlConfig from "@/config/UrlConfig";
import axios from "axios";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: { Callout },
  name: "Callouts",
    data() {
      return {
        callouts: ref([])
      }
    },

    mounted() {
      this.getCallOutsDetail();
    },

    methods: {
      getCallOutsDetail(){
        const url = UrlConfig.urlHelper.hostUrl +'/get-aboutUs-callout' ;
        const header = UrlConfig.unAuthorizedUrlHeader;
        axios.get(url,header)
            .then(response => {
              let callOutsDetail = response.data.data ;
              if(response.data.status_code === 200 && callOutsDetail.length > 0){
                Object.entries(callOutsDetail).forEach(([key, value]) => {
                  this.callouts.push({
                    id:++key,
                    title:value.title,
                    icon:value.image_path,
                    description:value.description
                  });
                });
              }
            }).catch((error) => {
              toast.error(error.response.data.message, {
                timeout: 2000,
              });
        });
      },
    },

};
</script>

<style lang="scss" scoped></style>
